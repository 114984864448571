import RnIcon from '@/components/icon/icon.vue';
import RnButton from '@/components/button/button.vue';

import { downloadAttachment } from '@/utils/download-attachment';
import { ALLOWED_MIME_TYPES_PER_UPLOAD_TYPE } from '@/views/app/pup/new-request/new-request.const';
import { FileTypes } from '@/enums/file-types';

export default {
	name: 'rn-file-item',
	components: {
		RnIcon,
		RnButton,
	},
	props: {
		attachment: {
			type: Object,
			default: {},
		},
		deleteHandler: {
			type: Function,
			default: () => {
				// do nothing
			},
		},
	},
	computed: {
		isPhoto() {
			return ALLOWED_MIME_TYPES_PER_UPLOAD_TYPE.photo.mimeTypes.includes(this.attachment.fileInfo.contentType);
		},

		canDelete() {
			return this.attachment.fileInfo.typeId !== FileTypes.SurveyAttachment;
		},
	},
	methods: {
		downloadFile() {
			const newAttachment = {
				...this.attachment.fileInfo,
				fileNameWithExtension: this.attachment.fileInfo.fileName,
			};

			downloadAttachment(newAttachment);
		},
	},
};
