export const FileTypes = Object.freeze({
	Document: '2bdcd980-fe0c-44d6-942f-4c7fbb1af4a8',
	BatteryAttachment: '43413a60-0dce-439f-b061-7efa3590a2d6',
	Survey: '534b13f7-1241-4b30-a8e5-ec92d643fcc5',
	SurveyAttachment: '5cc8206a-bb28-48ef-98d0-7713387f3671',
});

export const UploadTypes = Object.freeze({
	Photo: 'photo',
	StatusReport: 'statusReport',
	Survey: 'survey',
	SurveyAttachment: 'surveyAttachment',
});
